<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col v-if="isAllowed('customer','c')" cols="4">
        <v-container fluid>
          <v-row>
            <v-col class='d-flex flex-row align-center'>
              <h1>Add New {{singular}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Name *"
                  outlined
                  v-model="cus.name"
              />
              <v-text-field
                  label="Phone *"
                  outlined
                  v-model="cus.phone"
              />
              <v-text-field
                  label="Email"
                  outlined
                  v-model="cus.email"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="createCustomer" :loading="createCustomerLoading" :disabled="!this.cus.name || !this.cus.phone">Create</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-text-field
                      v-model="options.search"
                      label="Search"
                      single-line
                      hide-details
                  ></v-text-field>
                  <v-btn color="info" fab x-small><v-icon>mdi-magnify</v-icon></v-btn>
                </v-card-title>
                <v-data-table
                    :headers="headers"
                    :items="customers"
                    :options.sync="options"
                    :server-items-length="pagination.dataCount"
                    :loading="pagination.loading"
                    :items-per-page="-1"
                    class="elevation-1"
                    :footer-props="{
                      itemsPerPageOptions: pagination.pageSizeOptions
                    }"
                    @click:row="rowClick"
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        search: '',
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['name'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15
        },
        updatingRoute: false,
        init: false,

        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: 'ID', align: 'start', sortable: true, value: 'id' },
          { text: 'Name', align: 'start', sortable: true, value: 'name' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', sortable: true, value: 'email' }
        ],
        editMode: false,
        customers: [],
        cus: {
          contact: {}
        },

        singular: "Customer",
        singularLower: "customer",
        plural: "Customers",
        pluralLower: "customers",
        createCustomerLoading: false
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "name"]
        this.options.sortDesc = [oldQuery.order || true]
        this.options.search = oldQuery.search || ""
        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
      this.init = true;

      this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

      await this.getAllData();
      this.init = false;
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed', 'getGlobalValue'])
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "name"]
          this.options.sortDesc = [oldQuery.order || true]
          this.options.search = oldQuery.search || ""
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      //used for pagination
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        return oldQuery;
      },
      //used for pagination end
      rowClick(cus){
        this.$router.push({ path: `/${this.pluralLower}/view/${cus.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async createCustomer(){
        try {
          this.createCustomerLoading = true
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.cus)
          if(res.data.error) throw res.data.error

          this.snack(`${this.singular} Created 🎉`, "success");
          this.data={};
          this.cus = {}
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.createCustomerLoading = false
        }
      },
      async getAllData(){
        try {
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: this.options.sortDesc[0],
            search: this.options.search
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.customers = res.data.data

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};
          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.replace({path: `/${this.pluralLower}`, query: newQuery});
            }
            else{
              if(this.$route.path===`/${this.pluralLower}`) await this.$router.push({path: `/${this.pluralLower}`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      }
    }
  }
</script>
